import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { InputBase, Typography, Grid } from "@mui/material";
import Link from "@mui/material/Link";
import { useForm } from "react-hook-form";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SelectPlaceholder from "../../common/select";
import { authActions } from "../../store/auth";
import { headerActions } from "../../store/header";
import CustomizedSteppers from '../stepper/index';

import firmService from "../../services/firmDetails";
import * as common from "../common";
import { Auth } from 'aws-amplify';
import userService from "../../services/user";
import bglService from "../../services/bgl";
import {
    bglOperations,
} from '../../services/constants';
import hubSpotService from "../../services/hubspot";
import BGLHealthCheckModal from "../bglHealthCheckModal"

const FirmDetailsButton = styled(Button)({
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    textTransform: "none",
    fontSize: "14px",
    padding: "10px 20px",
    border: "none",
    fontWeight: 400,
    lineHeight: "24px",
    backgroundColor: "#1A2842",
    color: "white",
    fontFamily: ["Poppins"].join(","),
    "&:hover": {
        backgroundColor: "#1A2842",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    "&:active": {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        backgroundColor: "#1A2842",
    },
    "&:focus": {
        backgroundColor: "#1A2842",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    "&:disabled": {
        color: "white",
        backgroundColor: "#8C94A0;",
    },
});

const useStyles = makeStyles((theme) => ({
    firmDetailsBox: {
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'flex-start',
        padding: "32px",
        position: "absolute",
        backgroundColor: "#FFFFFF",
        boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        height: "calc(100% - 32px)",
        maxHeight: "830px",
        overflowX: "hidden",
        overflowY: "auto",
    },
    firmDetailsLbl: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "32px",
        color: "#000000",
        marginBlock: 0,
        marginBottom: 32,
    },
    textFieldLabel: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "24px",
        color: "#000000",
        marginBlock: 0,
    },
    smallTextFieldLabel: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "9px",
        lineHeight: "16px",
        color: "#000000",
        letterSpacing: "0.1em",
    },
    red: {
        color: "red",
    },
    textField: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        background: "#ECEFF1",
        alignSelf: "stretch",
        height: "44px",
        paddingLeft: 8,
        paddingRight: 8,
    },
    text14: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "24px",
    },
    crossIcon: {
        position: "absolute",
        right: "24px",
        cursor: "pointer",
    },
    marginBtm: {
        marginTop: "0px",
        marginBottom: "32px",
    },
}));

export default function FirmDetails() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const open = useSelector((state) => state.header.openFirmDetails);
    const userData = (common.getCookie('userData') && common.getCookie('userData') !== undefined) ? JSON.parse(common.getCookie('userData')) : ""
    const userSub = (common.getCookie('userSub') && common.getCookie('userSub') !== undefined) ? common.getCookie('userSub') : ""
    const allStates = [
        "NSW",
        "QLD",
        "VIC",
        "WA",
        "SA",
        "NT",
        "ACT",
        "TAS",
    ];

    const [state, setState] = React.useState('');
    const [country, setCountry] = React.useState('');
    const smallDeviceMatch = !useMediaQuery("(min-width:656px)");
    const bglAuthCode = useSelector((state) => state.header.bglAuthCode);
    const [isBglFirmDetailsLoaded, setIsBglFirmDetailsLoaded] = React.useState(false);


    const isValidObject = (obj, name = "") => {
        console.log("Checking object" + name);
        let containsStatus = obj.includes("statusCode=");
        let statusValue = +(obj.substring(obj.indexOf("statusCode=") + "statusCode=".length, obj.indexOf(',')));
        if (!containsStatus || statusValue !== 200)
            console.log("Object " + name + " is invalid".replace("  ", " "), obj);
        if (containsStatus === false) console.log(name + " does not contain a statusCode property");
        if (containsStatus && statusValue !== 200) console.log(statusValue + " is not a valid status value");
        console.log("Returning " + ((containsStatus && statusValue === 200)).toString() + " for object " + name);
        return (containsStatus && statusValue === 200);
    }

    const getBody = (obj) => {
        return obj.split("body=")[1]?.slice(0, -1);
    };
    
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset,
    } = useForm({});
    useEffect(async () => {
        if (bglAuthCode && !isBglFirmDetailsLoaded) {
            const user = await Auth.signIn(userData.email, userData.password);
            await userService.updateUsersKey({
                type: "sub",
                data: { sub: userSub, userId: userData.id }
            });
            const userInfo = await userService.getUser(user.attributes.sub);
            const userObj = userService.getUserFlatObject(userInfo);
            const hubspotUserData = {
                sub: userInfo.sub,
                email: userInfo.email,
                firstname: userInfo.given_name,
                lastname: userInfo.family_name
            };
            await hubSpotService.pushData({
                type: "create",
                userData: hubspotUserData
            });

            dispatch(
                authActions.handleUserAuth({
                    loggedIn: false,
                    user: JSON.stringify(userObj),
                })
            );
            const response = await bglService.getUserBglData(bglOperations.ACCOUNTDETAILS, { offset: 0 })
            console.log(response);
            if (!isValidObject(response)) {
               
//            if ((Object.keys(JSON.parse(response.slice(0, -1)) === "status") && (JSON.parse(response.slice(0, -1)).status > 400 || JSON.parse(response.slice(0, -1)).status < 600))) {
                dispatch(authActions.handleUserAuth({ loggedIn: false, user: null }));
                dispatch(headerActions.handleOpenBGLHealthCheckModal());
                return ;
            }
            console.log(getBody(response));
            const firmData = JSON.parse(getBody(response))["Account"]
            reset({
                businessName: firmData?.name,
                addressLine1: firmData?.address1,
                addressLine2: firmData?.address2,
                city: firmData?.address3,
                phone: firmData?.mobile,
                email: firmData?.email,
                keyContact: firmData?.contact,
                state: firmData?.state?.toUpperCase(),
                postCode: firmData?.postCode,
            })
            setState(firmData?.state?.toUpperCase())
            setCountry(firmData?.country)
            dispatch(headerActions.handleFirmDetails(firmData))
            setIsBglFirmDetailsLoaded(true);
        }
    }, [isBglFirmDetailsLoaded, bglAuthCode, dispatch, reset, userData])


    const onSubmit = async (data) => {
        try {
            const user = await Auth.signIn(userData.email, userData.password);
            const userInfo = await userService.getUser(user.attributes.sub);
            const userObj = await userService.getUserFlatObject(userInfo);
            dispatch(
                authActions.handleUserAuth({
                    loggedIn: false,
                    user: JSON.stringify(userObj),
                })
            );
            data.sub = userSub;

            data.country = country;
            await firmService.add(data);

            const hubspotData = {
                company: data.businessName,
                phone: data.phone,
                keycontact: data.keyContact,
                address: data.addressLine1 + (data.addressLine2 ? data.addressLine2 : ''),
            };
            await hubSpotService.pushData({
                type: "update",
                sub: userInfo.sub,
                userData: hubspotData
            });

            dispatch(headerActions.handleFirmDetails(data));
            dispatch(headerActions.handleCloseFirmDetails());

            dispatch(headerActions.handleOpenSubscriptionPlan());
        } catch (error) {
            dispatch(authActions.handleUserAuth({ loggedIn: false, user: null }));
        }
    };


    const onClose = async () => {
        reset();
        await Auth.signOut();
        dispatch(authActions.handleUserAuth({ loggedIn: false, user: null }));
        dispatch(headerActions.handleCloseFirmDetails());
    };


    return (
        <>
            <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        className={classes.firmDetailsBox}
                        sx={{ width: `${smallDeviceMatch ? "calc(100% - 32px)" : "638px"}` }}
                    >
                        <X className={classes.crossIcon} onClick={onClose} />
                        <Typography className={classes.firmDetailsLbl}>Create your Account</Typography>

                        <Grid className={classes.marginBtm}>
                            <CustomizedSteppers step={2}></CustomizedSteppers>
                        </Grid>
                        <Box>
                            <Grid>
                                <Typography variant="smaller" className="profileHeading">
                                    FIRM DETAILS
                                </Typography>
                            </Grid>
                        </Box>
                        <Grid mt={2}>
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={6}>
                                    <Typography mt={2}>
                                        {errors.businessName && (
                                            <Typography className={classes.textFieldLabel}>
                                                <span className={classes.red}>Business Name *</span>
                                            </Typography>
                                        )}
                                        {!errors.businessName && (
                                            <Typography className={classes.textFieldLabel}>
                                                {errors.businessName}
                                                Business Name <span className={classes.red}>*</span>
                                            </Typography>
                                        )}
                                        <InputBase
                                            className={`${classes.textField} ${classes.text14}`}
                                            sx={{
                                                width: `${smallDeviceMatch ? "auto" : "270px"}`,
                                                maxWidth: "270px",
                                            }}
                                            {...register("businessName", { required: true, maxLength: 100 })}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={`${smallDeviceMatch ? 12 : 6}`}
                                    sx={{
                                        paddingTop: `${smallDeviceMatch ? "32px !important" : "16px"}`,
                                    }}
                                >
                                    <Typography mt={2}>
                                        {errors.addressLine1 && (
                                            <Typography className={classes.textFieldLabel}>
                                                <span className={classes.red}>Address Line 1 *</span>
                                            </Typography>
                                        )}
                                        {!errors.addressLine1 && (
                                            <Typography className={classes.textFieldLabel}>
                                                Address Line 1 <span className={classes.red}>*</span>
                                            </Typography>
                                        )}
                                        <InputBase
                                            className={`${classes.textField} ${classes.text14}`}
                                            sx={{
                                                width: `${smallDeviceMatch ? "auto" : "270px"}`,
                                                maxWidth: "270px",
                                            }}
                                            {...register("addressLine1", { required: true, maxLength: 100 })}
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={6}>
                                    <Typography mt={2}>
                                        {errors.email && (
                                            <Typography className={classes.textFieldLabel}>
                                                <span className={classes.red}>Email *</span>
                                            </Typography>
                                        )}
                                        {!errors.email && (
                                            <Typography className={classes.textFieldLabel}>
                                                Email <span className={classes.red}>*</span>
                                            </Typography>
                                        )}
                                        <InputBase
                                            className={`${classes.textField} ${classes.text14}`}
                                            sx={{ width: `${smallDeviceMatch ? "auto" : "270px"}` }}
                                            disabled
                                            {...register("email", {
                                                required: true,
                                                pattern:
                                                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            })}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={`${smallDeviceMatch ? 12 : 6}`}
                                    sx={{
                                        paddingTop: `${smallDeviceMatch ? "32px !important" : "16px"}`,
                                    }}
                                >
                                    <Typography mt={2}>
                                        {errors.addressLine2 && (
                                            <Typography className={classes.textFieldLabel}>
                                                <span className={classes.red}>Address Line 2</span>
                                            </Typography>
                                        )}
                                        {!errors.addressLine2 && (
                                            <Typography className={classes.textFieldLabel}>Address Line 2</Typography>
                                        )}
                                        <InputBase
                                            className={`${classes.textField} ${classes.text14}`}
                                            sx={{
                                                width: `${smallDeviceMatch ? "auto" : "270px"}`,
                                                maxWidth: "277px",
                                            }}
                                            {...register("addressLine2", { required: false, maxLength: 100 })}
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={6}>
                                    <Typography mt={2}>
                                        {errors.phone && (
                                            <Typography className={classes.textFieldLabel}>
                                                <span className={classes.red}>Phone *</span>
                                            </Typography>
                                        )}
                                        {!errors.phone && (
                                            <Typography className={classes.textFieldLabel}>
                                                Phone <span className={classes.red}>*</span>
                                            </Typography>
                                        )}
                                        <InputBase
                                            type="mobile"
                                            placeholder="+61 4xx xxx xxx"
                                            className={`${classes.textField} ${classes.text14}`}
                                            sx={{ width: `${smallDeviceMatch ? "auto" : "270px"}` }}
                                            {...register("phone", {
                                                required: true,
                                                pattern:
                                                    /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/,
                                            })}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={`${smallDeviceMatch ? 12 : 6}`}
                                    sx={{
                                        paddingTop: `${smallDeviceMatch ? "32px !important" : "16px"}`,
                                    }}
                                >
                                    <Typography mt={2}>
                                        {errors.city && (
                                            <Typography className={classes.textFieldLabel}>
                                                <span className={classes.red}>City *</span>
                                            </Typography>
                                        )}
                                        {!errors.city && (
                                            <Typography className={classes.textFieldLabel}>
                                                City<span className={classes.red}>*</span>
                                            </Typography>
                                        )}
                                        <InputBase
                                            className={`${classes.textField} ${classes.text14}`}
                                            sx={{
                                                width: `${smallDeviceMatch ? "auto" : "270px"}`,
                                                maxWidth: "270px",
                                            }}
                                            {...register("city", { required: true, maxLength: 100 })}
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={6}>
                                    <Typography mt={2}>
                                        {errors.keyContact && (
                                            <Typography className={classes.textFieldLabel}>
                                                <span className={classes.red}>Key Contact *</span>
                                            </Typography>
                                        )}
                                        {!errors.keyContact && (
                                            <Typography className={classes.textFieldLabel}>
                                                Key Contact <span className={classes.red}>*</span>
                                            </Typography>
                                        )}
                                        <InputBase
                                            className={`${classes.textField} ${classes.text14}`}
                                            sx={{ width: `${smallDeviceMatch ? "auto" : "270px"}` }}
                                            {...register("keyContact", {
                                                required: true,
                                            })}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={`${smallDeviceMatch ? 12 : 6}`}
                                    sx={{
                                        paddingTop: `${smallDeviceMatch ? "32px !important" : "16px"}`,
                                    }}
                                >
                                    <Typography mt={2}>
                                        <SelectPlaceholder
                                            errors={errors}
                                            placeHolderText={`--`}
                                            width={`${smallDeviceMatch ? "75px" : "200px"}`}
                                            list={allStates}
                                            name={"state"}
                                            value={state}
                                            setValue={setState}
                                            {...register("state", {
                                                required: true,
                                                validate: (value) => allStates.includes(value),
                                            })}
                                            control={control}
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={6}></Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={`${smallDeviceMatch ? 12 : 6}`}
                                    sx={{
                                        paddingTop: `${smallDeviceMatch ? "32px !important" : "16px"}`,
                                    }}
                                >
                                    <Typography mt={2}>
                                        {errors.postCode && (
                                            <Typography className={classes.textFieldLabel}>
                                                <span className={classes.red}>Postcode *</span>
                                            </Typography>
                                        )}
                                        {!errors.postCode && (
                                            <Typography className={classes.textFieldLabel}>
                                                Postcode<span className={classes.red}>*</span>
                                            </Typography>
                                        )}
                                        <InputBase
                                            className={`${classes.textField} ${classes.text14}`}
                                            sx={{
                                                width: `${smallDeviceMatch ? "auto" : "270px"}`,
                                                maxWidth: "270px",
                                            }}
                                            {...register("postCode", { required: true, maxLength: 100 })}
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography mt={5} sx={{ textAlign: "end" }}>
                            <Link
                                onClick={onClose}
                                underline="always"
                                color="inherit"
                                className={classes.text14}
                                style={{
                                    cursor: "pointer",
                                    textUnderlineOffset: "8pt",
                                    color: "black",
                                    marginLeft: smallDeviceMatch ? 0 : 150,
                                }}
                            >
                                {"Cancel"}
                            </Link>
                            <FirmDetailsButton
                                variant="contained"
                                style={{ marginLeft: 34 }}
                                type="submit"
                                disabled={
                                    errors.email ||
                                    errors.businessName ||
                                    errors.addressLine1 ||
                                    errors.addressLine2 ||
                                    errors.city ||
                                    errors.phone ||
                                    errors.keyContact ||
                                    errors.state ||
                                    errors.postCode
                                }
                            >
                                Next
                            </FirmDetailsButton>
                        </Typography>
                    </Box>
                </form>
            </Modal>
            <BGLHealthCheckModal />
        </>
    );
}
