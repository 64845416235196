import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Typography } from "@mui/material";
const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% )',
        right: 'calc(50% )',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#0099FF',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#0099FF',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#F2F2F2',
        borderTopWidth: 10,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#000000',
    display: 'flex',
    height: 69,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#000000',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#000000',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 2,
        height: 12,
        // borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-circle" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};


const steps = ['User Details', 'BGL Consent', 'Firm Details', 'Subscription'];

export default function CustomizedSteppers(changeStep) {
    return (
        <Stack sx={{ width: '100%' }}>
            <Stepper alternativeLabel activeStep={changeStep.step} connector={<QontoConnector />}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={QontoStepIcon} mb={2}>
                            {(index === changeStep.step) ? (
                                <Typography style={{ fontWeight: "bold", marginTop: '-44px' }}>{label}</Typography>
                            ) : (
                                <Typography style={{ marginTop: '-44px' }}>{label}</Typography>
                            )}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Stack >
    );
}
