import {API, Auth} from "aws-amplify";
import {createUserInfo, updateUserInfo, createStateToUserInfo} from "../graphql/mutations";
import {
    getUserBySub,
    getUserByEmail,
    getBglToken,
    updateBglToken,
    checkEmail,
    getUserByPlanId
} from "../graphql/queries";

const userService = {
    add: async (userData) => {
        const res = await API.graphql({
            query: createUserInfo,
            variables: {
                input: {
                    sub: userData.sub,
                    email: userData.email,
                    given_name: userData.given_name,
                    family_name: userData.family_name,
                    isActive: true,
                    state: userData.state,
                    bglToken: "",
                    bglIsValid: false,
                },
            },
            authMode: "AWS_IAM",
        });

        // perform updates to other DynamoDB table here
        let stateRes = await API.graphql({
            query: createStateToUserInfo,
            variables: {
                input: {
                    state: res.data?.createUserInfo?.state,
                    userId: res.data?.createUserInfo?.id,
                },
            },
            authMode: "AWS_IAM",
        });
        return res.data?.createUserInfo;
    },
    getUser: async (sub) => {
        const res = await API.graphql({
            query: getUserBySub,
            variables: {sub},
        });
        return res.data?.getUserBySub?.items[0];
    },
    updateUser: async (data) => {
        const user = await Auth.currentUserInfo();
        const res = await userService.getUser(user.attributes.sub);
        let userInfo = {};
        userInfo = JSON.parse(res[data.type]);
        userInfo = {
            ...userInfo,
            ...data.data,
        };
        let variables = {
            input: {
                id: res.id,
                [data.type]: JSON.stringify(userInfo),
            },
        };

        const updateUser = await API.graphql({
            query: updateUserInfo,
            variables,
            authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        return updateUser?.data?.updateUserInfo;
    },
    updateUsersKey: async (data) => {
        let variables = {
            input: {
                id: data.data.userId,
                [data.type]: data.data[data.type],
            },
        };

        const updateUser = await API.graphql({
            query: updateUserInfo,
            variables,
            authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        return updateUser?.data?.updateUserInfo;
    },
    getUserFlatObject: (res) => {
        const userInfo = {
            id: res.id,
            email: res.email,
            sub: res.sub,
            accountCreationDate: res.createdAt,
            family_name: res.family_name,
            given_name: res.given_name,
            isActive: res.isActive,
            state: res.state,
            bglIsValid: res.bglIsValid,
            bglTokenExpirationDate: res.bglTokenExpirationDate,
            bglRefreshExpirationDate: res.bglRefreshExpirationDate,
        };
        return userInfo;
    },
    getUserBglToken: async (code, state) => {
        const response = await API.graphql({
            query: getBglToken,
            variables: {code, state},
            authMode: "AWS_IAM",
        });
        return response.data?.getBglToken || [];
    },
    getUserBglTokenUP: async (code, state) => {
        const response = await API.graphql({
            query: getBglToken,
            variables: {code, state},
            authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        return response.data?.getBglToken || [];
    },
    updateUserBglToken: async () => {
        const response = await API.graphql({
            query: updateBglToken,
            variables: {},
            authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        return response.data?.updateBglToken || [];
    },
    addDataToUserState: async (userId, state) => {
        let stateRes = await API.graphql({
            query: createStateToUserInfo,
            variables: {
                input: {
                    state: state,
                    userId: userId,
                },
            },
            authMode: "API_KEY",
        });
    },
    userEmailExists: async (email) => {
        /*const response = await API.graphql({
            query: checkEmail,
            variables: { email },
            authMode: "AWS_IAM",
        });
        return response.data?.checkEmail || [];
    },*/
        return await Auth.signIn(email.toLowerCase(), '123').then(res => {
            console.log(JSON.stringify(res));
            return true;
        }).catch(error => {
            console.log(JSON.stringify(error));
            if (error.code === 'UserNotFoundException') {
                // User doesn't exist
                return false;
            }
            // Handle other errors
            throw error;
        });
    }
};

export default userService;
