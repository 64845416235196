import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { Typography, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { headerActions } from "../../store/header";
import { SecondaryBtn } from "../../assets/primitives/buttons";
import { X } from "react-feather";

const useStyles = makeStyles((theme) => ({
    bglConsentBox: {
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'flex-start',
        padding: "32px",
        position: "absolute",
        backgroundColor: "#FFFFFF",
        boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        height: "42%",
        width: "765px",
        maxHeight: "45%",
        overflowX: "hidden",
        overflowY: "auto",
    },
    bglConsentLbl: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "32px",
        color: "#000000",
        marginBlock: 0,
        marginBottom: 32,
    },
    textFieldLabel: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "24px",
        color: "#000000",
        marginBlock: 0,
    },
    smallTextFieldLabel: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "9px",
        lineHeight: "16px",
        color: "#000000",
        letterSpacing: "0.1em",
    },
    red: {
        color: "red",
    },
    textField: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        background: "#ECEFF1",
        alignSelf: "stretch",
        height: "44px",
        paddingLeft: 8,
        paddingRight: 8,
    },
    text16: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
    },
    text14: {
        fontFamily: "Poppins",
        fontStyle: "small",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "24px",
    },
    crossIcon: {
        position: "absolute",
        right: "24px",
        cursor: "pointer",
    },
    marginBtm: {
        marginTop: "0px",
        marginBottom: "32px",
    },
    circle: {
        stroke: "url(#linearColors)",
    },
}));


export default function BGLHealthCheckModal() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const open = useSelector((state) => state.header.BGLHealthCheckModal);
    const smallDeviceMatch = !useMediaQuery("(min-width:656px)");

    const onClose = () => {
        dispatch(headerActions.handleCloseBGLHealthCheckModal());
    };
    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                className={classes.bglConsentBox}
                sx={{ width: `${smallDeviceMatch ? "calc(100% - 32px)" : "638px"}` }}
            >
                <X className={classes.crossIcon} onClick={onClose} />
                <Grid mt={6} style={{ textAlign: "center" }}>
                    <img src="/broken-connection.jpg" style={{ height: 58, width: 58, marginTop: 5 }} />
                    <Typography mt={2} className={classes.text16}>
                        BGL Servers cannot be reached at this time
                    </Typography>
                    <Typography mt={2} className={classes.text14}>
                        You may encounter issues using accountantsGPS until proper connectivity is restored.
                    </Typography>
                    <Typography mt={2} className={classes.text14}>
                        If the situation persists, please contact <a href="mailto: support@accountantsgps.com.au">support@accountantsgps.com.au</a>
                    </Typography>
                    <Typography mt={5}>
                        <SecondaryBtn value={'Close'} onClick={onClose} />
                    </Typography>
                </Grid>
            </Box>
        </Modal >
    );
}
