import { API, graphqlOperation } from 'aws-amplify';
import {
    getQuestionnaireResponseByFund,
    getQuestionnaireResponseByProductId
} from '../graphql/queries';
import { createQuestionnaireResponse, updateQuestionnaireResponse } from '../graphql/mutations';
import { questionnaireResponseStatus } from './constants';

const questionnaireReponseService = {
    getQuestionnaireResponseByProductId: async (productId) => {
        let nextToken = null;
        let items = [];

        while (true) {
            const responseData = await API.graphql(graphqlOperation(getQuestionnaireResponseByProductId, {
                productId: productId,
                nextToken: nextToken,
                limit: 50
            }));
            nextToken = responseData?.data?.getQuestionnaireResponseByProductId?.nextToken;
            items = items.concat(responseData?.data?.getQuestionnaireResponseByProductId?.items);

            if (!nextToken) {
                break;
            }
        }

        const response = items?.sort((a, b) => {
            return new Date(b.updatedAt) - new Date(a.updatedAt);
        });
        let result = {}
        if (response.length > 0) {
            if (response[0].status === questionnaireResponseStatus.COMPLETE) {
                result.MCUStatus = 'done';
            }
            result.MCUData = response.reduce((a, b) => {
                return new Date(a.updatedDate) > new Date(b.updatedDate) ? a : b;
            });
            return result;
        } else {
            return {
                MCUStatus: undefined,
                MCUData: undefined
            };
        }
    },
    getQuestionnaireResponseByFundId: async (fundId) => {
        let nextToken = null;
        let items = [];

        while (true) {
            const responseData = await API.graphql(
                graphqlOperation(getQuestionnaireResponseByFund, {
                    fundId: fundId,
                    nextToken: nextToken,
                    limit: 50,
                    filter: {status: {eq: questionnaireResponseStatus.INPROGRESS}}
                })
            );
            nextToken = responseData?.data?.getQuestionnaireResponseByFund?.nextToken;
            items = items.concat(responseData?.data?.getQuestionnaireResponseByFund?.items);

            if (!nextToken) {
                break;
            }
        }

        const response = items?.sort((a, b) => {
            return new Date(b.updatedAt) - new Date(a.updatedAt);
        });
        let result = {};
        if (response.length > 0) {
            if (response[0].status === questionnaireResponseStatus.COMPLETE) {
                result.MCUStatus = "done";
            }
            result.MCUData = response.reduce((a, b) => {
                return new Date(a.updatedDate) > new Date(b.updatedDate) ? a : b;
            });
            return result;
        } else {
            return {
                MCUStatus: undefined,
                MCUData: undefined,
            };
        }
    },
    getAllQuestionnaireResponseByFund: async (fundId, limit, nextToken) => {
        const response = await API.graphql(graphqlOperation(getQuestionnaireResponseByFund, {
            fundId: fundId,
            limit,
            nextToken,
            sortDirection: "DESC"
        }));
        return response.data?.getQuestionnaireResponseByFund
    },
    getLatestCompleteQRByEmailByProduct: async (productId) => {

        let nextToken = null;
        let items = [];

        while (true) {
            const responseData = await API.graphql(graphqlOperation(getQuestionnaireResponseByProductId, {
                productId: productId,
                nextToken: nextToken,
                limit: 50
            }));
            nextToken = responseData?.data?.getQuestionnaireResponseByProductId?.nextToken;
            items = items.concat(responseData?.data?.getQuestionnaireResponseByProductId?.items);

            if (!nextToken) {
                break;
            }
        }

        if (items?.length > 0) {
            const completedQRs = items?.filter((item) => item.status === questionnaireResponseStatus.COMPLETE);
            if (completedQRs.length > 0) {
                return completedQRs?.reduce((a, b) => {
                    return new Date(a.updatedDate) > new Date(b.updatedDate) ? a : b;
                });
            }
        }
        return undefined;

    },
    getAllLatestCompleteQRByEmailByProduct: async (productId) => {
         let nextToken = null;
        let items = [];

        while (true) {
            const responseData = await API.graphql(graphqlOperation(getQuestionnaireResponseByProductId, {
                productId: productId,
                nextToken: nextToken,
                limit: 50
            }));
            nextToken = responseData?.data?.getQuestionnaireResponseByProductId?.nextToken;
            items = items.concat(responseData?.data?.getQuestionnaireResponseByProductId?.items);

            if (!nextToken) {
                break;
            }
        }

        if (items?.length > 0) {
            return items?.filter((item) => item.status === questionnaireResponseStatus.COMPLETE);
        }
        return undefined;
    },
    createQuestionnaireResponse: async (info) => {
        const now = Math.floor(Date.now() / 1000);
        return API.graphql(graphqlOperation(createQuestionnaireResponse, {
            input: {
                id: info.id,
                productId: info.productId,
                fundId: info.fundId,
                surveyId: info.surveyId,
                type: info.type,
                status: info.doComplete ? (info.isSurveyNotQualify ? questionnaireResponseStatus.DOESNOTQUALIFY : questionnaireResponseStatus.COMPLETE) : questionnaireResponseStatus.INPROGRESS,
                data: JSON.stringify(info.data),
                currentPageNo: info.currentPageNo,
                email: info.email,
                createdDate: now,
                updatedDate: now,
                maxPageNoVisited: info.maxPageNoVisited,
                sub: info.sub,
                createdBy:info.createdBy
            },
            authMode: "AMAZON_COGNITO_USER_POOLS"
        }));
    },
    updateQuestionnaireResponse: async (info) => {
        // FFS-653 guard rail around unsafe updates - this should be made more robust in the future 
        if(!info.data.firmName) throw new Error(`Attempt to update questionaireResponse ${info.id} with incomplete data`)
        const now = Math.floor(Date.now() / 1000);
        const updateResult = await API.graphql(graphqlOperation(updateQuestionnaireResponse, {
            input: {
                id: info.id,
                status: info.doComplete ? (info.isSurveyNotQualify ? questionnaireResponseStatus.DOESNOTQUALIFY : questionnaireResponseStatus.COMPLETE) : questionnaireResponseStatus.INPROGRESS,
                data: JSON.stringify(info.data),
                currentPageNo: info.currentPageNo,
                updatedDate: now,
                maxPageNoVisited: info.maxPageNoVisited
            }
        }));
        return updateResult;
    }
}

export default questionnaireReponseService;