import * as React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import {makeStyles} from "@mui/styles";
import {styled} from "@mui/material/styles";
import {Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {reportGenerationStatus} from "../../services/constants";
import {headerActions} from "../../store/header";
import documentService from '../../services/document';
import {useHistory} from "react-router-dom";
import {Storage} from 'aws-amplify';

const WhiteButton = styled(Button)({
    height: "44px",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    background: "#FFFFFF",
    border: "1px solid #1A2842",
    boxSizing: "border-box",
    color: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    fontFamily: ["Poppins"].join(","),
    "&:hover": {
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    "&:active": {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        backgroundColor: "#FFFFFF",
    },
    "&:focus": {
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    "&:disabled": {
        color: "#8C94A0",
        backgroundColor: '#FFFFFF',
        border: '1px solid #8C94A0'
    },
});

const BlueButton = styled(Button)({
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    textTransform: "none",
    fontSize: "14px",
    padding: "10px 20px",
    border: "none",
    fontWeight: 400,
    lineHeight: "24px",
    backgroundColor: "#1A2842",
    color: "white",
    fontFamily: ["Poppins"].join(","),
    "&:hover": {
        backgroundColor: "#1A2842",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    "&:active": {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        backgroundColor: "#1A2842",
    },
    "&:focus": {
        backgroundColor: "#1A2842",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    "&:disabled": {
        color: "white",
        backgroundColor: "#8C94A0;",
    },
});

const useStyles = makeStyles((theme) => ({
    text14: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "24px",
        color: '#000000'
    },
    text24: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "32px",
        color: '#000000'
    },
    text16: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
    },
    text18: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "24px",
    },
    text11: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "11px",
        lineHeight: "16px",
    },
    link: {
        color: "black",
        textDecoration: "underline",
        textDecorationColor: "black",
    },
}));

export default function Congratulations(props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const isSurveyNotQualify = useSelector(
        (state) => state.survey.isSurveyNotQualify
    );
    const product = props.product;
    const [surveyResponse, setSurveyResponse] = React.useState(props.results);
    const reportStatus = props.reportStatus;
    const firmDetails = useSelector(state => state.header.firmDetails);
    const selectedProduct = JSON.parse(localStorage.getItem('selectedProduct'));
    const [productImageUrl, setProductImageUrl] = React.useState("");

    React.useEffect(() => {
        Storage.get(selectedProduct.image)
            .then((data) => setProductImageUrl(data))
            .catch((err) => console.log('error fetching file'));

    }, []);
    const handleClose = () => {
        props.onClose();
    };

    const returnHome = () => {
        history.push("/");
        handleClose();
    };

    const onDownload = async () => {
        const documentsSub = await documentService.getDocumentByQRId(surveyResponse?.id);
        if (documentsSub.length > 0) {
            const pdfDoc = documentsSub?.find((item) => (item?.documentLocation?.endsWith('.pdf')));
            if (pdfDoc) {
                let resultPdf = await Storage.get(pdfDoc.documentLocation, {level: 'public', download: true});
                let pdfUrl = URL.createObjectURL(resultPdf.Body);

                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = pdfDoc.documentLocation.substring(pdfDoc.documentLocation.indexOf('/') + 1);
                link.click();
                URL.revokeObjectURL(pdfUrl);
            }
        }
    };

    const onViewReport = async () => {
        const documentsSub = await documentService.getDocumentByQRId(surveyResponse?.id);
        if (documentsSub) {
            const pdfDocument = documentsSub?.find((item) => (item?.documentLocation?.endsWith('.pdf')));
            const htmlDocument = documentsSub?.find((item) => (item?.documentLocation?.endsWith('.html')));
            dispatch(
                headerActions.setPdfDocument({pdfDocument})
            );
            dispatch(
                headerActions.setHtmlDocument({htmlDocument})
            );
        }
        handleClose();
        dispatch(headerActions.setSelectedProduct({selectedProduct: product}));
        dispatch(
            headerActions.setOpenHtmlReportViewer({openHtmlReportViewer: true})
        );
    };

    return (
        <>
            <Grid container>
                <Grid item xs={4}/>
                <Grid item xs={4}>
                    {isSurveyNotQualify && <>
                        <Typography className={classes.text24} style={{marginBottom: 8}}>
                            Thank You
                        </Typography>
                        <Typography className={classes.text14} style={{marginBottom: 24}}>
                            Due to your current circumstances, the online advice tools will not be the best way to help
                            you.
                        </Typography>
                        <Typography className={classes.text14} style={{marginBottom: 24, marginRight: 60}}>
                            We suggest that you book in to speak with a accountantGPS coach who can ensure that you
                            receive the correct services.
                        </Typography>
                    </>}
                    {!isSurveyNotQualify && <>
                        <Typography mt={10} className={classes.text24} style={{marginBottom: 8, textAlign: 'center'}}>
                            Congratulations!
                        </Typography>
                        <Typography mt={3} style={{textAlign: 'center'}} className={classes.text14}>
                            Thank you for finishing the {props.title}.
                        </Typography>
                    </>}

                    {!isSurveyNotQualify && <Box sx={{
                        width: '500px',
                        height: "215px",
                        marginLeft: '15%',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #000000',
                        marginBottom: 5
                    }}>
                        <Grid container style={{justifyContent: 'center', marginTop: "20px"}}>
                            <Grid style={{marginTop: '20px'}}>
                                <Box style={{backgroundColor: '#1A2842', marginLeft: 20, height: 56, width: 56}}>
                                    <a href="/"><img src={productImageUrl} style={{height: 60, width: 60}}/></a>
                                </Box>
                            </Grid>
                            <Grid style={{marginLeft: 10, marginTop: 30, marginBottom: '20px'}}>
                                <Typography className={classes.text11} style={{
                                    letterSpacing: '0.25em',
                                    textTransform: 'uppercase',
                                    color: '#8C94A0'
                                }}>
                                    {product.category}
                                </Typography>
                                <Typography className={classes.text14} style={{fontWeight: "700"}}>
                                    {props.title}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography className={classes.text11}
                                    style={{textAlign: 'center', paddingRight: 20, paddingTop: 16, paddingBottom: 20}}>
                            <WhiteButton
                                disabled={!(reportStatus === reportGenerationStatus.hasPdf || reportStatus === reportGenerationStatus.hasBoth)}
                                variant="contained"
                                style={{marginRight: 10}}
                                onClick={onDownload}>
                                Download Print Version
                            </WhiteButton>
                            <BlueButton
                                disabled={!(reportStatus === reportGenerationStatus.hasHtml || reportStatus === reportGenerationStatus.hasBoth)}
                                variant="contained" onClick={onViewReport}>
                                View Report
                            </BlueButton>
                        </Typography>
                    </Box>}
                    <Typography style={{textAlign: 'center'}}>
                        <Link onClick={returnHome} underline="always" color="inherit" className={classes.text16}
                              style={{
                                  cursor: 'pointer',
                                  textUnderlineOffset: '8pt',
                                  color: 'black',
                                  marginLeft: "40px"
                              }}>
                            {'back to home'}
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={4}/>
            </Grid>
        </>
    );
}
