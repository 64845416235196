import * as React from 'react';

import { Box, Typography, Badge, List, ListItem, ListItemText } from '@mui/material';
import { X } from 'react-feather';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TertiaryBtnDark } from '../../assets/primitives/buttons';
import './megaMenu.css';
import { authActions } from "../../store/auth";
import { headerActions } from "../../store/header";
import { ExternalLink } from 'react-feather';
import ChangePassword from '../changePassword/changePassword';
const moment = require('moment');

export default function ProfileMenu(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const showProfileDetailsDot = useSelector(state => state.notification.showProfileDetailsDot);
    const user = useSelector(state => state.auth.user);
    let [changePasswordModal, setChangePasswordModal] = React.useState(false);

    const dispatchHeaderActions = () => {
        dispatch(headerActions.handlePanelState({
            profileMenu: false,
            shopping: false,
            notification: false
        }));
    }

    const logOutUser = async () => {
        localStorage.removeItem('selectedProduct');
        await Auth.signOut();
        dispatch(authActions.handleUserAuth({ loggedIn: false, user: null }));
        dispatch(headerActions.handleSelectedFund({}));
        dispatch(headerActions.handleFirmDetails({}));
        dispatch(headerActions.setSelectedProduct({ selectedProduct: undefined }))
        dispatchHeaderActions();
        history.replace('/');
    }

    const gotoBGLConsentPage = () => {
        history.push('/profile/bglConsentStatus');
        dispatchHeaderActions();
    }

    const goToMySubscriptionPage = () => {
        history.push('/profile/mySubscription');
        dispatchHeaderActions();
    }


    const handleClose = () => {
        dispatchHeaderActions();
    }

    const gotoProfilePage = () => {
        history.push('/profile');
        dispatchHeaderActions();
    }

    const openChangePassword = () => {
        setChangePasswordModal(!changePasswordModal);
    }

    return (<>
        <Box sx={{ padding: '36px 48px' }}>
            <List dense={true}>
                <ListItem className="padding0px"><ListItemText className="listItemLeftMargin" primary={<Typography variant="smaller" className='profileHeading'>PROFILE</Typography>} /></ListItem>
                <ListItem className="padding0px cursor" onClick={gotoProfilePage}>
                    <ListItemText className="listItemLeftMargin" primary={
                        <Badge variant={showProfileDetailsDot ? 'dot' : ''} className="marginLeft24">
                            <Typography variant="small">Account Details</Typography>
                        </Badge>
                    } />
                </ListItem>
                <ListItem className="padding0px cursor" onClick={openChangePassword}><ListItemText className="listItemLeftMargin" primary={<Typography variant="small">Change Password <ExternalLink style={{ fontSize: "18px", marginLeft: "4px", height: "15px", width: "14px" }} /></Typography>} /> </ListItem>
                <ListItem className="padding0px cursor" onClick={gotoBGLConsentPage}>
                    <ListItemText className="listItemLeftMargin" primary={
                        < Badge variant={user?.bglIsValid === false && Math.abs(user.bglTokenExpirationDate - moment().unix()) <= 0 ? 'dot' : ''} className="marginLeft24">
                            <Typography variant="small">BGL Consent</Typography>
                        </Badge>
                    } />
                </ListItem>
                <ListItem className="padding0px cursor" onClick={goToMySubscriptionPage}>
                    <ListItemText className="listItemLeftMargin" primary={
                        < Badge className="marginLeft24">
                            <Typography variant="small">My Subscription</Typography>
                        </Badge>
                    } />
                </ListItem>
            </List>
            <Box sx={{ width: '100%', textAlign: 'center' }}><TertiaryBtnDark value="Sign Out" onClick={logOutUser} className="signOutBtn" /></Box>
            <X size={38} className={['crossIcon', 'dialogCloseIcon'].join(' ')} onClick={handleClose} />
        </Box>
        {changePasswordModal && <ChangePassword onClose={openChangePassword} />}
    </>
    );
}
