import React from "react";
// import * as Survey from "survey-react";
import * as Survey from "survey-core";
import * as SurveyReact from "survey-react-ui";
import * as widgets from "surveyjs-widgets";
import "./styles/defaultV2-theme/defaultV2.scss";
import {dollarSign} from "../../assets/svg/svg";
import "./styles/extra.scss";
import {useSelector} from 'react-redux';
import {surveyIds} from "../../services/constants";
import {RendererFactory} from "survey-core";
import {ReactQuestionFactory} from "survey-react-ui";
import SurveySelect from './SurveySelect';

Survey.StylesManager.applyTheme("defaultV2");
widgets.inputmask(Survey);
widgets.nouislider(Survey);

//add nouislider column type into matrix columns (run-time)
Survey.matrixDropdownColumnTypes.nouislider = {
    properties: []
};

// Register the CustomSelect component as a renderer under a custom name "sv-dropdown-react"
ReactQuestionFactory.Instance.registerQuestion(
    "sv-dropdown-react",
    (props) => {
        return React.createElement(SurveySelect, props);
    }
);
// Register "sv-dropdown-react" as a renderer for questions whose `type` is "dropdown" and `renderAs` property is "dropdown-react"
RendererFactory.Instance.registerRenderer(
    "dropdown",
    "dropdown-react",
    "sv-dropdown-react"
);

function getChoices(survey, matrixName) {
    let matrix = survey.getQuestionByName(matrixName);
    let choices = [];
    matrix.visibleRows.forEach(x => {
        let value = x.value['member_number'];
        let text = x.value['member_first_name'] + " " + x.value['member_last_name'];
        choices.push(new Survey.ItemValue(value, text));
    });
    return choices;
}

function SurveyComponent(props) {
    console.log("SurveyComponent props", props);
    const user = useSelector(state => state.auth.user);
    const surveyMap = useSelector(state => state.survey.surveyMap);
    const selectedFY = useSelector(state => state.header.selectedFY);
    const surveyData = useSelector(state => state.survey.data);

    console.log({selectedFY});
    let surveyJson = surveyMap[props.productSurveyId]?.surveyJson;
    let surveyJsonStr = JSON.stringify(surveyJson);
    //dynamically sets the column title based on the selected FY
    surveyJsonStr = surveyJsonStr.replace("{{SJS_FY_CURRENT-4_COL_TITLE}}", "FY " + (selectedFY - 5) + "/" + (selectedFY - 4));
    surveyJsonStr = surveyJsonStr.replace("{{SJS_FY_CURRENT-3_COL_TITLE}}", "FY " + (selectedFY - 4) + "/" + (selectedFY - 3));
    surveyJsonStr = surveyJsonStr.replace("{{SJS_FY_CURRENT-2_COL_TITLE}}", "FY " + (selectedFY - 3) + "/" + (selectedFY - 2));
    surveyJsonStr = surveyJsonStr.replace("{{SJS_FY_CURRENT-1_COL_TITLE}}", "FY " + (selectedFY - 2) + "/" + (selectedFY - 1));
    surveyJsonStr = surveyJsonStr.replace("{{SJS_FY_CURRENT_COL_TITLE}}", "FY " +   (selectedFY - 1) + "/" + selectedFY);
    surveyJson = JSON.parse(surveyJsonStr);

    const survey = new Survey.Model(surveyJson);
    survey.currentPageNo = props.clickedPageIndex;
    survey.data = props.data.firmName ? props.data : surveyData;
    survey.data = {...survey.data, ...{birthDate: user.birthdate}};
    if (props.productSurveyId !== surveyIds.SMSF001) {
        survey.data = {...survey.data};
        // survey.data = { ...survey.data, ...{ MCU_questions: props.mcuQuestions }};
    }

    survey.isCompleteTrigger = props.isCompleteTrigger;
    window.survey = survey;

    const setIsCompleteTrigger = () => {
        let meetCompleteTrigger = false;

        if (surveyJson && surveyJson.triggers) {
            for (let trigger of surveyJson?.triggers) {
                for (let question of survey.getAllQuestions()) {
                    if (trigger.expression.includes(question.name)) {
                        if (trigger.type === 'complete' && trigger.expression === `{${question.name}} = '${question.value}'`) {
                            meetCompleteTrigger = true;
                            survey.isCompleteTrigger = true;
                            break;
                        } else {
                            survey.isCompleteTrigger = false;
                        }
                    }
                }
                if (meetCompleteTrigger) break;
            }
        }
    }

    setIsCompleteTrigger();

    survey.showTitle = false;
    survey.onAfterRenderQuestion.add(function (survey, options) {
        for (let el of document.querySelectorAll('.sd-footer')) el.remove();
    });

    survey.onErrorCustomText.add(function (sender, options) {
        if (options.name === "required") {
            options.text = '';
        }
    });

    survey.onCurrentPageChanged.add((sender, options) => {
        if (options.newCurrentPage.name === 'Cash Flow') {
            survey.getQuestionByName('bfr_member').choices = getChoices(survey, 'smsf_member_questions');
        }
    });
    survey
        .onAfterRenderQuestionInput
        .add(function (survey, options) {
            // Add dollar sign to text input when adding new row
            if (options.question?.inputMask === 'currency') {
                const currency = options.htmlElement;
                let parentDiv = currency?.parentNode;

                if (parentDiv) {
                    // Add image between span
                    let span = document.createElement("span");
                    let img = document.createElement('img');
                    img.setAttribute('src', dollarSign);
                    span.appendChild(img);

                    parentDiv.className += " currency";
                    parentDiv.insertBefore(span, currency)
                }
            }
        });

    survey
        .onAfterRenderQuestion
        .add(function (survey, options) {
            for (let item of options.htmlElement.querySelectorAll('div.noUi-target')) {
                let slider = item?.parentNode;

                if (slider) {
                    slider.className += " noUi-width";
                }
            }
        });

    survey.onValueChanged.add(function (survey, options) {
        setIsCompleteTrigger();
    });

    survey
        .onAfterRenderPage
        .add(function (survey, options) {
            // Add dollar sign to text input 
            for (let currency of options.htmlElement.querySelectorAll('input[inputmode="decimal"]')) {
                let parentDiv = currency?.parentNode;

                if (parentDiv) {
                    // Add image between span
                    let span = document.createElement("span");
                    let img = document.createElement('img');
                    img.setAttribute('src', dollarSign);
                    span.appendChild(img);

                    parentDiv.className += " currency";
                    parentDiv.insertBefore(span, currency)
                }
            }
        });


    return (
        <SurveyReact.Survey model={survey}/>
    )
}

export default SurveyComponent;
