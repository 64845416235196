import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { Switch, Route } from "react-router-dom";
import McuReport from "./components/Reports/McuReport";
import MainBody from "./components/MainBody/Content";
import Sidebar from "./components/sidebar";
import Header from "./components/Header/header";
import { authActions } from "../src/store/auth";
import AboutUs from "./components/aboutUs";
import { surveyActions } from "./store/survey";
import Contact from "./components/contact";
import Profile from "./components/profile/profile";
import { productTypes } from "./services/constants";
import userService from "./services/user";
import { isMobile } from "react-device-detect";
import { headerActions } from "./store/header";
import FundsList from "./components/fundsList";
import FundDetails from "./components/fundDetails";
import HandleRedirectUri from "./components/handleRedirectUri";
import StripePricingTable from "./components/stripePricingTable";
import productService from "./services/product";
import surveyService from "./services/survey";
import firmService from "./services/firmDetails";
import { Storage } from 'aws-amplify';
import SubscribePlans from "./components/subscriptionPlan";

export default function App() {
    const dispatch = useDispatch();
    const [passwordUpdated, setPasswordUpdated] = useState(false);
    const handlePasswordUpdated = () => setPasswordUpdated(true);
    const handleCloseNotification = () => setPasswordUpdated(false);
    const getUserSession = async () => {
        try {
            const session = await Auth.currentSession();
            const userInfo = await userService.getUser(session.idToken.payload.sub);
            const firmDetails = await firmService.listFirmDetails();
            if (isMobile) {
                dispatch(headerActions.handleMobileWelcome());
            }
            let isUserGroupAdmin = false;
            try {
                const grps = session.getAccessToken().payload["cognito:groups"];
                if (grps && grps.indexOf("Admin") !== -1) {
                    isUserGroupAdmin = true;
                }
            } catch (e) {
            }
            dispatch(
                authActions.handleUserAuth({
                    loggedIn: true,
                    user: JSON.stringify(userService.getUserFlatObject(userInfo)),
                    isUserGroupAdmin,
                }));
            dispatch(headerActions.handleFirmDetails(firmDetails))

        } catch (e) {
            if (e !== "No current user") {
                //console.log(e);
            }
            dispatch(
                authActions.handleUserAuth({
                    loggedIn: false,
                    user: null,
                    isUserGroupAdmin: null,
                })
            );
        }
    };

    useEffect(() => {
        getUserSession();
    }, []);

    const getSurveyJson = async (productType) => {
        try {
            let response = await productService.getProductByType(productType);
            const productMap = {};

            if (response.data?.getProductByType?.items?.length > 0) {
                for (const product of response.data?.getProductByType?.items) {
                    if (product && product.surveyId) {
                        productMap[product.surveyId] = {};
                        productMap[product.surveyId].productId = product.id;
                        productMap[product.surveyId].product = product;

                        response = await surveyService.getSurveyBySurveyIdByStatus(
                            product.surveyId,
                            "active"
                        );
                        if (response.data?.getSurveyBySurveyIdByStatus?.items?.length > 0) {
                            const survey = response.data?.getSurveyBySurveyIdByStatus?.items?.reduce(
                                (a, b) => {
                                    return new Date(a.versionDate).getTime() >
                                        new Date(b.versionDate).getTime()
                                        ? a
                                        : b;
                                }
                            );
                            productMap[product.surveyId].surveyId = product.surveyId;

                            const fileURL = await Storage.get(survey.filename, {
                                download: true,
                            });
                            const surveyFromS3 = await fileURL.Body.text();
                            productMap[product.surveyId].surveyJson = JSON.parse(surveyFromS3);
                        }
                    }
                }
            }
            dispatch(
                surveyActions.setSurveyMap({
                    surveyMap: JSON.stringify(productMap),
                })
            );
        } catch (e) {
            // console.log(e);
        }
    };
    getSurveyJson(productTypes.SMSF).catch(console.error);

    return (
        <Box
            sx={{
                display: "flex",
                minHeight: "100vh",
                overflowX: "hidden",
            }}
        >
            <CssBaseline />
            <Header handlePasswordUpdated={handlePasswordUpdated} />
            <Sidebar />

            <Switch>
                <Route exact path="/">
                    <MainBody
                        passwordUpdated={passwordUpdated}
                        handleCloseNotification={handleCloseNotification}
                    />
                </Route>
                <Route path="/mcu_report">
                    <McuReport />
                </Route>
                <Route path="/contact">
                    <Contact />
                </Route>
                <Route path="/aboutUs">
                    <AboutUs />
                </Route>
                <Route path="/product/:productId">
                    <MainBody
                        passwordUpdated={passwordUpdated}
                        handleCloseNotification={handleCloseNotification}
                    />
                </Route>
                <Route path={["/profile/:panel", "/profile"]}>
                    <Profile />
                </Route>
                <Route exact path="/fundsList">
                    <FundsList />
                </Route>
                <Route exact path="/fundDetails">
                    <FundDetails />
                </Route>
                <Route exact path="/stripeTest">
                    <StripePricingTable />
                </Route>
                <Route exact path="/plansTest">
                    <SubscribePlans />
                </Route>
                <Route path="/redirected">
                    <HandleRedirectUri />
                </Route>
            </Switch>
        </Box>
    );
}
