import * as React from "react";
import { useEffect } from 'react';
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { InputBase, Typography, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import SelectPlaceholder from "../../common/select";
import { headerActions } from "../../store/header";
import { TertiaryBtnDark20 } from '../../assets/primitives/buttons';
import { SecondaryBtn } from "../../assets/primitives/buttons";
import firmService from "../../services/firmDetails";
import hubSpotService from "../../services/hubspot";


const useStyles = makeStyles((theme) => ({
    createAccountBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '16px',
        width: '800px',
        height: 'auto',
        paddingTop: '60px',
        paddingBottom: '60px'
    },
    createAccountLbl: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '32px',
        color: '#000000',
        marginBlock: 0,
        marginBottom: 32,
    },
    textFieldLabel: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        color: '#000000',
        marginBlock: 0,
    },
    smallTextFieldLabel: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '9px',
        lineHeight: '16px',
        color: '#000000',
        letterSpacing: '0.1em',
    },
    red: {
        color: "red",
    },
    textField: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        background: "#ECEFF1",
        alignSelf: "stretch",
        height: "44px",
        paddingLeft: 8,
        paddingRight: 8,
    },
    text14: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
    },
    crossIcon: {
        position: "absolute",
        right: "24px",
        cursor: "pointer",
    },
    marginBtm: {
        marginTop: "0px",
        marginBottom: "32px",
    },
}));
export default function BillingDetailsPage(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isUserLoggedIn = useSelector(state => state.auth.loggedIn);
    const user = useSelector(state => state.auth.user);
    const firmDetails = useSelector(state => state.header.firmDetails);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: {
            businessName: firmDetails?.businessName,
            addressLine1: firmDetails?.addressLine1,
            addressLine2: firmDetails?.addressLine2,
            city: firmDetails?.city,
            phone: firmDetails?.phone,
            businessEmail: firmDetails?.email,
            keyContact: firmDetails?.keyContact,
            postCode: firmDetails?.postCode,
            country: firmDetails?.country,
            State: firmDetails?.state
        },
    });
    const allStates = [
        "NSW",
        "QLD",
        "VIC",
        "WA",
        "SA",
        "NT",
        "ACT",
        "TAS",
    ];

    const [state, setState] = React.useState('');
    const [emailExistError, setEmailExistError] = React.useState(false);
    const smallDeviceMatch = !useMediaQuery("(min-width:656px)");

    useEffect(() => {
        setState(firmDetails?.state)
    },[])
    
    const onSubmit = async (data) => {
        try {
            data.id = firmDetails.id;
            data.sub = user?.sub;
            const response = await firmService.updateFirmDetails(data);

            const hubspotData = {
                companyname: data.businessName,
                phonenumber: data.phone,
                keycontact: data.keyContact,
                streetaddress: data.addressLine1 + data.addressLine2,
            };
            await hubSpotService.pushData({
                type: "update",
                sub: data.sub,
                userData: hubspotData,
            });

            dispatch(headerActions.handleFirmDetails(response))
        } catch (error) {
            // console.log("error", error)
        }
    };



    const closePanel = () => {
        props.func();
    }
    const onClose = () => {
        setEmailExistError(false);
        dispatch(headerActions.handleCloseFirmDetails());
    };

    const onEmailChange = () => {
        setEmailExistError(false);
    };


    return (
        <>
            {isUserLoggedIn && user && user.email && <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                    className={classes.createAccountBox}
                    sx={{ width: `${smallDeviceMatch ? "calc(100% - 32px)" : "638px"}` }}
                >
                    <Grid >
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={6}>
                                <Typography mt={2}>
                                    {errors.businessName && (
                                        <Typography className={classes.textFieldLabel}>
                                            <span className={classes.red}>Business Name *</span>
                                        </Typography>
                                    )}
                                    {!errors.businessName && (
                                        <Typography className={classes.textFieldLabel}>
                                            {errors.businessName}
                                            Business Name <span className={classes.red}>*</span>
                                        </Typography>
                                    )}
                                    <InputBase
                                        className={`${classes.textField} ${classes.text14}`}
                                        sx={{
                                            width: `${smallDeviceMatch ? "auto" : "384px"}`,
                                            maxWidth: "384px",
                                        }}
                                        {...register("businessName", { required: true, maxLength: 100 })}
                                    />
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={`${smallDeviceMatch ? 12 : 6}`}
                                sx={{
                                    paddingTop: `${smallDeviceMatch ? "32px !important" : "16px"}`,
                                }}
                            >
                                <Typography mt={2}>
                                    {errors.addressLine1 && (
                                        <Typography className={classes.textFieldLabel}>
                                            <span className={classes.red}>Address Line 1 *</span>
                                        </Typography>
                                    )}
                                    {!errors.addressLine1 && (
                                        <Typography className={classes.textFieldLabel}>
                                            Address Line 1 <span className={classes.red}>*</span>
                                        </Typography>
                                    )}
                                    <InputBase
                                        className={`${classes.textField} ${classes.text14}`}
                                        sx={{
                                            width: `${smallDeviceMatch ? "auto" : "384px"}`,
                                            maxWidth: "384px",
                                        }}
                                        {...register("addressLine1", { required: true, maxLength: 100 })}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={6}>
                                <Typography mt={2}>
                                    {errors.businessEmail && (
                                        <Typography className={classes.textFieldLabel}>
                                            <span className={classes.red}>Email *</span>
                                        </Typography>
                                    )}
                                    {!errors.businessEmail && (
                                        <Typography className={classes.textFieldLabel}>
                                            Email <span className={classes.red}>*</span>
                                        </Typography>
                                    )}
                                    <InputBase
                                        className={`${classes.textField} ${classes.text14}`}
                                        sx={{ width: `${smallDeviceMatch ? "auto" : "384px"}` }}
                                        onChange={onEmailChange}
                                        {...register("businessEmail", {
                                            required: true,
                                            pattern:
                                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        })}
                                    />
                                </Typography>

                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={`${smallDeviceMatch ? 12 : 6}`}
                                sx={{
                                    paddingTop: `${smallDeviceMatch ? "32px !important" : "16px"}`,
                                }}
                            >
                                <Typography mt={2}>
                                    {errors.addressLine2 && (
                                        <Typography className={classes.textFieldLabel}>
                                            <span className={classes.red}>Address Line 2 </span>
                                        </Typography>
                                    )}
                                    {!errors.addressLine2 && (
                                        <Typography className={classes.textFieldLabel}>
                                            Address Line 2
                                        </Typography>
                                    )}
                                    <InputBase
                                        className={`${classes.textField} ${classes.text14}`}
                                        sx={{
                                            width: `${smallDeviceMatch ? "auto" : "384px"}`,
                                            maxWidth: "384px",
                                        }}
                                        {...register("addressLine2", { required: false, maxLength: 100 })}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={6}>
                                <Typography mt={2}>
                                    {errors.phone && (
                                        <Typography className={classes.textFieldLabel}>
                                            <span className={classes.red}>Phone *</span>
                                        </Typography>
                                    )}
                                    {!errors.phone && (
                                        <Typography className={classes.textFieldLabel}>
                                            Phone <span className={classes.red}>*</span>
                                        </Typography>
                                    )}
                                    <InputBase
                                        className={`${classes.textField} ${classes.text14}`}
                                        sx={{ width: `${smallDeviceMatch ? "auto" : "384px"}` }}
                                        {...register("phone", {
                                            required: true,
                                            pattern:
                                                /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/,
                                        })}
                                    />
                                </Typography>

                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={`${smallDeviceMatch ? 12 : 6}`}
                                sx={{
                                    paddingTop: `${smallDeviceMatch ? "32px !important" : "16px"}`,
                                }}
                            >
                                <Typography mt={2}>
                                    {errors.city && (
                                        <Typography className={classes.textFieldLabel}>
                                            <span className={classes.red}>City *</span>
                                        </Typography>
                                    )}
                                    {!errors.city && (
                                        <Typography className={classes.textFieldLabel}>
                                            City<span className={classes.red}>*</span>
                                        </Typography>
                                    )}
                                    <InputBase
                                        className={`${classes.textField} ${classes.text14}`}
                                        sx={{
                                            width: `${smallDeviceMatch ? "auto" : "384px"}`,
                                            maxWidth: "384px",
                                        }}
                                        {...register("city", { required: true, maxLength: 100 })}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={6}>
                                <Typography mt={2}>
                                    {errors.keyContact && (
                                        <Typography className={classes.textFieldLabel}>
                                            <span className={classes.red}>Key Contact *</span>
                                        </Typography>
                                    )}
                                    {!errors.keyContact && (
                                        <Typography className={classes.textFieldLabel}>
                                            Key Contact <span className={classes.red}>*</span>
                                        </Typography>
                                    )}
                                    <InputBase
                                        className={`${classes.textField} ${classes.text14}`}
                                        sx={{ width: `${smallDeviceMatch ? "auto" : "384px"}` }}
                                        {...register("keyContact", {
                                            required: true,
                                        })}
                                    />
                                </Typography>

                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={`${smallDeviceMatch ? 12 : 6}`}
                                sx={{
                                    paddingTop: `${smallDeviceMatch ? "32px !important" : "16px"}`,
                                }}
                            >
                                <Typography mt={2}>
                                        <SelectPlaceholder
                                            errors={errors}
                                            placeHolderText={`--`}
                                            width={`${smallDeviceMatch ? "75px" : "200px"}`}
                                            list={allStates}
                                            name={"state"}
                                            value={state}
                                            setValue={setState}
                                            {...register("state", {
                                                required: true,
                                                validate: (value) => allStates.includes(value),
                                            })}
                                            control={control}
                                        />
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={6}>
                                <Typography mt={2}>
                                    {errors.postCode && (
                                        <Typography className={classes.textFieldLabel}>
                                            <span className={classes.red}>Postcode *</span>
                                        </Typography>
                                    )}
                                    {!errors.postCode && (
                                        <Typography className={classes.textFieldLabel}>
                                            Postcode <span className={classes.red}>*</span>
                                        </Typography>
                                    )}
                                    <InputBase
                                        className={`${classes.textField} ${classes.text14}`}
                                        sx={{ width: `${smallDeviceMatch ? "auto" : "384px"}` }}
                                        {...register("postCode", {
                                            required: true,
                                        })}
                                    />
                                </Typography>

                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={`${smallDeviceMatch ? 12 : 6}`}
                                sx={{
                                    paddingTop: `${smallDeviceMatch ? "32px !important" : "16px"}`,
                                }}
                            >
                                <Typography mt={2}>
                                    {errors.country && (
                                        <Typography className={classes.textFieldLabel}>
                                            <span className={classes.red}>Country *</span>
                                        </Typography>
                                    )}
                                    {!errors.country && (
                                        <Typography className={classes.textFieldLabel}>
                                            Country<span className={classes.red}>*</span>
                                        </Typography>
                                    )}
                                    <InputBase
                                        className={`${classes.textField} ${classes.text14}`}
                                        disabled
                                        sx={{
                                            width: `${smallDeviceMatch ? "auto" : "384px"}`,
                                            maxWidth: "384px",
                                        }}
                                        {...register("country", { required: true, maxLength: 100 })}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box mt={4}>
                        <Box variant="small" style={{ display: "flex" }}>
                            <div style={{ marginRight: "20px" }}><TertiaryBtnDark20 type="submit" value={'Save'} /></div>
                            <div><SecondaryBtn value={'Cancel'} onClick={closePanel} /></div>
                        </Box>
                    </Box>
                </Box>
            </form>
            }</>
    );
}