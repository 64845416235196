import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { Typography, Grid } from "@mui/material";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { headerActions } from "../../store/header";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CustomizedSteppers from '../stepper/index';
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import * as common from "../common";
import bglService from "../../services/bgl";

const CreateAccountButton = styled(Button)({
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  textTransform: "none",
  fontSize: "14px",
  padding: "10px 20px",
  border: "none",
  fontWeight: 400,
  lineHeight: "24px",
  backgroundColor: "#1A2842",
  color: "white",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#1A2842",
  },
  "&:focus": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:disabled": {
    color: "white",
    backgroundColor: "#8C94A0;",
  },
});
const useStyles = makeStyles((theme) => ({
  bglConsentBox: {
    padding: "32px",
    position: "absolute",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    height: "calc(100% - 32px)",
    maxHeight: "830px",
    overflowX: "hidden",
    overflowY: "auto",
  },
  bglConsentLbl: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",
    marginBlock: 0,
    marginBottom: 32,
  },
  textFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    marginBlock: 0,
  },
  smallTextFieldLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "9px",
    lineHeight: "16px",
    color: "#000000",
    letterSpacing: "0.1em",
  },
  red: {
    color: "red",
  },
  textField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#ECEFF1",
    alignSelf: "stretch",
    height: "44px",
    paddingLeft: 8,
    paddingRight: 8,
  },
  text14: {
    fontFamily: "Poppins",
    fontStyle: "small",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
  },
  crossIcon: {
    position: "absolute",
    right: "24px",
    cursor: "pointer",
  },
  marginBtm: {
    marginTop: "0px",
    marginBottom: "32px",
  },
  circle: {
    stroke: "url(#linearColors)",
  },
}));

export default function BGLConsent() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const open = useSelector((state) => state.header.openBGLConsent);
  const smallDeviceMatch = !useMediaQuery("(min-width:656px)");

  const getBglState = ()=> (common.getCookie('userData') && common.getCookie('userData') !== undefined) ? JSON.parse(common.getCookie('userData')).state : "";

  async function getConnectYourBGLAccountURL(){
    let response = await bglService.getBglDetails()
    const {clientId, redirectUri, integrationsUri} = response?.body;
    return `${integrationsUri}/u/oauth/authorize?response_type=code&client_id=${clientId}&scope=investment&redirect_uri=${redirectUri}&state=${getBglState()}`
  }

  async function connectYourBGLAccount() {
    const url = await getConnectYourBGLAccountURL() 
    openInNewTab(url);
  }

  const onClose = () => {
    dispatch(headerActions.handleCloseBGLConsent());
  };

  const openInNewTab = url => {
    dispatch(headerActions.handleCloseBGLConsent());
    dispatch(headerActions.handleOpenBGLConsentSpinner());
    let height = 600;
    let width = 900;
    let left = (window.screen.width - width) / 2
    let top = (window.screen.height - height) / 2
    window.open(url, '_blank', `location=yes,top=${top},left=${left},width=${width},height=${height}`);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={classes.bglConsentBox}
        sx={{ width: `${smallDeviceMatch ? "calc(100% - 32px)" : "638px"}` }}
      >
        <X className={classes.crossIcon} onClick={onClose} />
        <Typography className={classes.bglConsentLbl}>
          Create your account
        </Typography>

        <Grid mb={6}>
          <CustomizedSteppers step={1}></CustomizedSteppers>
        </Grid>
        <Box mb={5} className={classes.text12}>
          <Grid>
            <Typography style={{ display: "flex" }}>
              <div>
                <ErrorOutlineIcon style={{ fontSize: "medium", marginRight: "5px", marginTop: '3px' }} />
              </div>
              <div className={classes.text14}>
                To successfully register for an account with us, you must provide information about your business.
                Log in to BGL and provide your consent.
              </div>
            </Typography>
          </Grid>
        </Box>
        <Grid>
          <CreateAccountButton
            variant="contained"
            style={{ marginLeft: "30%" }}
            onClick={async () => await connectYourBGLAccount()}
          >
            Connect Your BGL Account
          </CreateAccountButton>
        </Grid>
      </Box>
    </Modal >
  );
}
