import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Grid, InputBase, Typography, Link } from '@mui/material';
import { Auth } from 'aws-amplify';
import { ChevronDown, Edit } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from "react-router-dom";
import { SecondaryBtn } from "../../assets/primitives/buttons";
import './profile.css'
import { authActions } from "../../store/auth";
import { notificationActions } from "../../store/notification";
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
//import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Footer from "../Footer";
import Toolbar from "@mui/material/Toolbar";
import { useForm } from 'react-hook-form';
import { TertiaryBtnDark20 } from '../../assets/primitives/buttons';
import ChangePassword from '../changePassword/changePassword';
import hubSpotService from '../../services/hubspot';
import NotificationMain from '../notification/notification';
import userService from '../../services/user';
import BillingDetailsPage from '../billingDetails';
import BGLConsentStatus from '../bglConsentStatus';
import MySubscription from '../MySubscription';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: '1px solid #D1D4D9',
  // '&:not(:last-child)': {
  //   borderBottom: 0,
  // },
  '&:before': {

  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ChevronDown size={24} />}
    {...props}
  />
))(({ theme }) => ({
  padding: '8px',
  minHeight: 'auto',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingRight: theme.spacing(3),
  borderTop: '1px solid #D1D4D9',
}));

const useStyles = makeStyles(theme => ({
  createAccountBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px',
    width: '800px',
    height: 'auto',
    paddingTop: '60px',
    paddingBottom: '60px',
    background: "#FAFAFA"
  },
  createAccountLbl: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
    marginBlock: 0,
    marginBottom: 32,
  },
  textFieldLabel: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000000',
    marginBlock: 0,
  },
  smallTextFieldLabel: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '9px',
    lineHeight: '16px',
    color: '#000000',
    letterSpacing: '0.1em',
  },
  red: {
    color: 'red',
  },
  textField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#ECEFF1',
    alignSelf: 'stretch',
    height: '44px',
    paddingLeft: 8,
    paddingRight: 8,
  },
  text14: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
  },
  phoneField: {
    padding: '0px 0px 0px 4px !important',
    height: '0px',
    display: 'inline-block',
    fontSize: '14px'
  },
  crossIcon: {
    position: 'absolute',
    right: '24px',
    cursor: 'pointer'
  }
}));


export default function Profile() {
  const classes = useStyles();
  const { panel } = useParams();
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(state => state.auth.loggedIn);
  const user = useSelector(state => state.auth.user);
  let [changePasswordModal, setChangePasswordModal] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      firstname: user?.given_name,
      lastname: user?.family_name,
      email: user?.email,
    },
  });


  const password = React.useState({});
  const [emailExistError, setEmailExistError] = React.useState(false);
  const [showPersonalNotificaton, setShowPersonalNotificaton] = React.useState(false);


  const onSubmit = async (data) => {
    try {
      const cognitoUser = await Auth.currentUserPoolUser();
      const userData = {
        family_name: data.lastname,
        given_name: data.firstname,
      }
      const updatedUser = await userService.updateUser({
        type: 'personalDetails', data: {
          family_name: data.lastname,
          given_name: data.firstname,
        }
      });
      // const updateAttributes = await Auth.updateUserAttributes(cognitoUser, userData);
      if (updatedUser) {
        setShowPersonalNotificaton(true);
        dispatch(authActions.handleUserAuth({ loggedIn: true, user: JSON.stringify(userService.getUserFlatObject(updatedUser)) }));
        hubSpotService.pushData({
          'type': 'update',
          'sub': cognitoUser.attributes.sub,
          'firstname': data.firstname,
          'lastname': data.lastname,
        });
      }

    } catch (error) {
      //console.log(error);
    }
    //resetDateOfBirthLists();
  };

  let location = useLocation();

  useEffect(() => {
    if (!panel) {
      setExpanded('accountdetails');
    } else {
      setExpanded(panel);
    }
  }, [location]);

  const [expanded, setExpanded] = useState(panel || 'accountdetails');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const openChangePassword = () => {
    setChangePasswordModal(!changePasswordModal);
  }


  React.useEffect(async () => {
    dispatch(notificationActions.setShowProfileDetailsDot({ showProfileDetailsDot: false }));
    // const customer = await paymentService.getStripeCustomer();
  }, []);

  const closePanel = () => {
    setExpanded(false);
  }

  const closeAction = () => {
    setShowPersonalNotificaton(false);
  }

  return (
    <>
      <Box bgcolor="#FAFAFA" sx={{ padding: '106px 32px', width: '100%', flexGrow: 1, pb: 0 }}>
        <Typography variant="smaller" className='pageHeading'>PROFILE</Typography><br></br>
        {/* <Box sx={{padding:'8px 8px 16px',marginTop:'20px',width:'100%',borderBottom:'1px solid #D1D4D9'}}>
            <Typography variant="big">Personal Details</Typography>
        </Box> */}
        <Accordion style={{ background: "#FAFAFA" }} expanded={expanded === 'accountdetails'} onChange={handleChange('accountdetails')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography variant="big">Account Details</Typography><Box sx={{ margin: '0 0 0 16px' }}><Edit size={24} /></Box>

          </AccordionSummary>
          <AccordionDetails >
            {showPersonalNotificaton && <NotificationMain boxStyle={{ width: '100%', backgroundColor: '#ECEFF1' }}
              title={"Personal details updated."} description={''} actionText={''}
              closeAction={closeAction} />}
            {isUserLoggedIn && user && user.email && <form onSubmit={handleSubmit(onSubmit)}>
              <Box className={classes.createAccountBox} >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    {errors.firstname && <Typography className={classes.textFieldLabel} >
                      <span className={classes.red}>First Name *</span>
                    </Typography>}
                    {!errors.firstname && <Typography className={classes.textFieldLabel} >
                      {errors.firstname}
                      First Name <span className={classes.red}>*</span>
                    </Typography>}
                    <InputBase
                      className={`${classes.textField} ${classes.text14}`}
                      sx={{ width: '384px' }}
                      {...register("firstname", { required: true, maxLength: 100 })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {errors.lastname && <Typography className={classes.textFieldLabel} >
                      <span className={classes.red}>Last Name *</span>
                    </Typography>}
                    {!errors.lastname && <Typography className={classes.textFieldLabel} >
                      Last Name <span className={classes.red}>*</span>
                    </Typography>}
                    <InputBase
                      className={`${classes.textField} ${classes.text14}`}
                      sx={{ width: '384px' }}
                      {...register("lastname", { required: true, maxLength: 100 })}
                    />
                  </Grid>
                </Grid>

                <Box mt={4}>
                  {errors.email && <Typography className={classes.textFieldLabel} >
                    <span className={classes.red}>Email Address *</span>
                  </Typography>}
                  {!errors.email && <Typography className={classes.textFieldLabel} >
                    Email Address <span className={classes.red}>*</span>
                  </Typography>}
                  <InputBase
                    className={`${classes.textField} ${classes.text14}`}
                    sx={{ width: '384px' }}
                    disabled
                    //onChange={onEmailChange}
                    {...register("email", {
                      required: true,
                      pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    })}
                  />
                  {emailExistError && <span className={classes.red}><Typography variant="small">An account with the given email already exists.</Typography></span>}
                </Box>
                <Grid container spacing={4}>
                  <Grid item xs={3}>
                    <Box mt={4}>
                      <Typography className={classes.textFieldLabel} >
                        <span>Password</span>
                      </Typography>
                      <InputBase
                        type='text'
                        value='*************'
                        readOnly={true}
                        className={`${classes.textField} ${classes.text14}`}
                        sx={{ background: 'none !important' }}
                      />

                    </Box>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography mt={4} sx={{ marginTop: '48px' }}>
                      <Link onClick={openChangePassword} color="inherit" className={classes.textFieldLabel} style={{ cursor: 'pointer', textUnderlineOffset: '8pt', color: 'black', }}>
                        {'Change Password?'}
                      </Link></Typography>
                  </Grid>
                </Grid>

                <Box sx={{ marginTop: '25px' }}>
                  <Box variant="small" style={{ display: "flex" }}>
                    <div style={{ marginRight: "20px" }}><TertiaryBtnDark20 type="submit" value={'Save'} /></div>
                    <div><SecondaryBtn value={'Cancel'} onClick={closePanel} /></div>
                  </Box>
                </Box>
              </Box>
            </form>}
            {changePasswordModal && <ChangePassword onClose={openChangePassword} />}
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ background: "#FAFAFA" }} expanded={expanded === 'billingDetails'} onChange={handleChange('billingDetails')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography variant="big">Billing Details</Typography><Box sx={{ margin: '0 0 0 16px' }}><Edit size={24} /></Box>
          </AccordionSummary>
          <AccordionDetails>
            <BillingDetailsPage func={closePanel} />
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ background: "#FAFAFA" }} expanded={expanded === 'bglConsentStatus'} onChange={handleChange('bglConsentStatus')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography variant="big">BGL Consent</Typography><Box sx={{ margin: '0 0 0 16px' }}><Edit size={24} /></Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box variant="small">
              <BGLConsentStatus func={closePanel} />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ background: "#FAFAFA" }} expanded={expanded === 'mySubscription'} onChange={handleChange('mySubscription')}>
          <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
            <Typography variant="big">My Subscription</Typography><Box sx={{ margin: '0 0 0 16px' }}><Edit size={24} /></Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box variant="small">
              <MySubscription func={closePanel} />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Toolbar />
        <Footer />
      </Box>
    </>
  );
}
