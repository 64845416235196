import React, { useState } from "react";
import { Box, AppBar, Breadcrumbs, Link, Stack, Badge, Drawer, Modal, Typography } from '@mui/material';
import { Bell, ChevronDown, X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import './header.css'
import { accountantsGPSLogo } from "../../assets/svg/svg";
import { TertiaryBtn, SecondaryBtn, BtnWithIcon } from "../../assets/primitives/buttons";
import { authActions } from "../../store/auth";
import { headerActions } from "../../store/header";
import SignIn from '../signIn';
import ForgotPassword from '../forgotPassword';
import CreateAccount from '../createAccount';
import CodeVerificationBase from "../codeVerificationBase";
import CreateNewPassword from "../createNewPassword";
import ProfileMenu from "../megaMenu";
import HtmlReportViewer from '../MainBody/HtmlReportViewer/htmlReportViewer';
import FullDialog from '../MainBody/FullDialog/fullDialog';
import {
    surveyIds,
} from '../../services/constants';
import BGLConsent from "../bglConsent";
import FirmDetails from "../firmDetails";
import SelectSubscriptionModal from "../SelectSubscriptionModal"
import CreateAccountConsent from "../createAccountConsent";
import BGLConsentSpinner from "../bglConsentSpinner";
import PasswordUpdatedModal from "../passwordUpdatedModal";

const Header = ({ handlePasswordUpdated }) => {
    const dispatch = useDispatch();
    const isUserLoggedIn = useSelector(state => state.auth.loggedIn);
    const user = useSelector(state => state.auth.user);
    const surveyMap = useSelector(state => state.survey.surveyMap);
    const surveyJson = surveyMap[surveyIds.SMSF001]?.surveyJson;
    const notificationList = useSelector(state => state.notification.notificationList);
    const forgotPassword = useSelector(state => state.header.forgotPassword);
    const openSignIn = useSelector(state => state.header.openSignIn);
    const codeVerificationBase = useSelector(state => state.header.codeVerificationBase);
    const passwordUpdatedModal = useSelector(state => state.header.passwordUpdatedModal);
    const createNewPassword = useSelector(state => state.header.createNewPassword);
    const panelState = useSelector(state => state.header.panelState);
    const selectedMenu = useSelector(state => state.header.selectedMenu);
    const selectedProduct = useSelector(state => state.header.selectedProduct);
    const openHtmlReportViewer = useSelector(state => state.header.openHtmlReportViewer);
    const pdfDocument = useSelector(state => state.header.pdfDocument);
    const htmlDocument = useSelector(state => state.header.htmlDocument);
    const openFullDialog = useSelector(state => state.header.openFullDialog);
    const showProfileDetailsDot = useSelector(state => state.notification.showProfileDetailsDot);
    const [showNotificationDot, setShowNotificationDot] = useState(true);

    window.addEventListener("message", function (event) {
        if (Object.keys(event.data)[0] === 'code') {
            document.cookie = `code=${Object.values(event.data)[0]}`;
            dispatch(headerActions.handleBglAuthCode({ code: Object.values(event.data)[0] }));
        }
    });
    const queryString = window.location.search;
    let bglCode = queryString.split('&');
    if (bglCode[0].split('=')[1] !== undefined) {
        window.opener?.postMessage({ code: bglCode[0].split('=')[1] }, "*");
    }

    const logInHandler = () => {
        dispatch(headerActions.handleOpenSignIn());
    }

    const logOutUser = () => {
        Auth.signOut();
        dispatch(authActions.handleUserAuth({ loggedIn: false, user: null }));
        dispatch(headerActions.handleCloseSignIn());
        dispatch(headerActions.handlePanelState({
            profileMenu: false,
            shopping: false,
            notification: false
        }));
    }
    const togglePanel = (panel, state) => {
        setOpen(false);
        if (panel === 'notification') setShowNotificationDot(false);
        dispatch(headerActions.handlePanelState({
            profileMenu: false,
            shopping: false,
            [panel]: state
        }));
    }
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        dispatch(headerActions.handlePanelState({
            profileMenu: false,
            shopping: false,
            notification: false
        }));
        dispatch(headerActions.handleOpenCreateAccount());
    }
    const handleClose = () => setOpen(false);

    React.useEffect(async () => {
        if (notificationList.length > 0 &&
            notificationList.filter((item) => item.read === false).length > 0) {
            if (panelState.notification === false) {
                setShowNotificationDot(true);
            }
        } else {
            setShowNotificationDot(false);
        }
    }, [notificationList]);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '1168px',
        height: '320px',
        color: '#000',
        bgcolor: 'background.paper',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
        p: '24px',
    };

    const closeHtmlReportViewer = () => {
        dispatch(headerActions.setOpenHtmlReportViewer({ openHtmlReportViewer: false }));
        dispatch(headerActions.setSelectedProduct({ selectedProduct: undefined }));
        dispatch(headerActions.setPdfDocument({ pdfDocument: undefined }));
        dispatch(headerActions.setHtmlDocument({ htmlDocument: undefined }));
    }

    const closeFullDialog = () => {
        dispatch(headerActions.setOpenFullDialog({ openFullDialog: false }));
    }


    return (
        <>
            <Box>
                <AppBar position="fixed" className="header" sx={{ flexFlow: 'row', zIndex: (theme) => theme.zIndex.drawer + 1 }}>

                    <Box sx={{ width: '267px', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <a href="/" ><img src={accountantsGPSLogo} className="mainLogo" /></a>
                    </Box>
                    <Box sx={{ flex: 1, 'display': 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Box sx={{ flex: 2, 'display': 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link underline="hover" color="#ffffff" href="/">
                                    <Typography variant="small">Home{(selectedMenu && selectedMenu !== 'Home') ? ` / ${selectedMenu}` : ''}</Typography>
                                </Link>
                            </Breadcrumbs>
                        </Box>
                        <Box sx={{ flex: 2, 'display': 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            {isUserLoggedIn && <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                                <Badge variant={showNotificationDot ? 'dot' : ''} className="marginLeft24" onClick={() => togglePanel("notification", true)}>
                                    <Bell color="#fff" />
                                </Badge>
                                <Badge variant={(!user?.bglIsValid || showProfileDetailsDot) ? 'dot' : ''} className="margin0" onClick={() => togglePanel("profileMenu", true)}>

                                    <BtnWithIcon value={`Hi, ${user.given_name}`} icon={<ChevronDown color='#fff' className="width16" />} />
                                </Badge>
                                {/* {welcome && <Welcome />} */}
                                {/* {mobileWelcome && <MobileWelcomeModal />} */}
                                {/* {mcu && <MCU />} */}
                                {openHtmlReportViewer && <HtmlReportViewer productSurveyId={selectedProduct?.surveyId} product={selectedProduct} pdfDocument={pdfDocument} htmlDocument={htmlDocument} open={openHtmlReportViewer} closeSurvey={closeHtmlReportViewer} />}
                                {openFullDialog && <FullDialog productSurveyId={surveyIds.SMSF001} product={surveyMap[surveyIds.SMSF001].product} open={openFullDialog} closeSurvey={closeFullDialog} />}
                            </Stack>}
                            {!isUserLoggedIn && <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                                <TertiaryBtn value={'Create Account'} className="marginLeft16" onClick={handleOpen} />
                                <SecondaryBtn value={'Sign In'} onClick={() => logInHandler()} />
                                {/* <SignIn /> */}
                                {forgotPassword && <ForgotPassword />}
                                <CreateAccount />
                                <BGLConsent />
                                <FirmDetails />
                                {/*add subscription component here*/}
                                <BGLConsentSpinner />
                                {openSignIn && <SignIn />}
                                {codeVerificationBase && <CodeVerificationBase handlePasswordUpdated={handlePasswordUpdated} />}
                                {passwordUpdatedModal && <PasswordUpdatedModal />}
                                {createNewPassword && <CreateNewPassword />}
                            </Stack>}
                            {/* Available for loggedInUsers to buy new subscription and see welcome page*/}
                            <SelectSubscriptionModal />
                            <CreateAccountConsent />
                        </Box>
                    </Box>
                </AppBar>
            </Box>

            <Drawer anchor="right"
                PaperProps={{ sx: { 'width': '400px', 'top': '73px', 'box-shadow': '0px 2px 5px rgba(0, 0, 0, 0.1)' } }}
                //ModalProps={{BackdropProps: {sx:{'background':'none'}}}} 
                open={panelState.profileMenu}
                onClose={() => togglePanel("profileMenu", false)}>
                <ProfileMenu />
            </Drawer>

            {/* <ProfileMenu
            open={panelState.profileMenu} 
            onClose={() => togglePanel("profileMenu", false)}
        /> */}

            <Drawer anchor="right"
                PaperProps={{ sx: { 'width': '400px', 'top': '73px', 'box-shadow': '0px 2px 5px rgba(0, 0, 0, 0.1)' } }}
                //ModalProps={{BackdropProps: {sx:{'background':'none'}}}} 
                open={panelState.shopping}
                onClose={() => togglePanel("shopping", false)}>
            </Drawer>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="create account"
                aria-describedby="create account for new user">
                <Box sx={modalStyle}>Coming Soon :)
                    <X class="crossIcon" onClick={handleClose} />
                </Box>
            </Modal>
        </>
    )
}

export default Header;
