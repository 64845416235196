import * as React from "react";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ConsentExpired from './consent-expired.svg';
import ConsentApproved from './consent-approved.svg';
import { TertiaryBtnDark20 } from '../../assets/primitives/buttons';
import { headerActions } from "../../store/header";
import BGLConsentSpinner from "../bglConsentSpinner";
import bglService from "../../services/bgl";
import userService from "../../services/user";
const moment = require('moment');

const useStyles = makeStyles((theme) => ({
    consentBox: {
        // position: "absolute",
        width: "232px",
        height: "187.5px",
        left: "650px",
        top: "484px",
        display: 'flex',
    },
    createAccountBox: {
        marginTop: "150px",
        marginBottom: "150px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '16px',
        width: '800px',
        height: 'auto',
        marginLeft: 'auto'
    },
    textAlign: {
        marginLeft: 'auto',
        display: "table-cell",
        width: "250px",
        height: "200px",
        padding: "10px",
        border: "3px dashed #1c87c9",
        verticalAlign: "middle",
        textAlign: 'center'
    },
    text28: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: '32px',
    },
    text14: {
        width: "250px",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        wordBreak: "break-word",
        fontSize: "14px",
        textAlign: "center",
    },
    text12: {
        width: "250px",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        wordBreak: "break-word",
        fontSize: "12px",
        textAlign: "center",
    }
}))

export default function BGLConsentStatus(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const isUserLoggedIn = useSelector(state => state.auth.loggedIn);
    const user = useSelector(state => state.auth.user);
    const [redirectUri, setRedirectUri] = React.useState('');
    const [bglClientId, setBglClientId] = React.useState('');
    const [integrationsUri, setIntegrationsUri] = React.useState('');

    useEffect(async () => {
        try {
            if (redirectUri === '') {
                console.log("Getting BGL Details...");
                let response = await bglService.getBglDetailsUP()
                const {clientId, redirectUri, integrationsUri} = response?.body;
                setRedirectUri(redirectUri)
                setBglClientId(clientId)
                setIntegrationsUri(integrationsUri)
            }
        } catch (e) {
            //console.log(e);
        }
    }, [redirectUri, bglClientId, integrationsUri])

    const openInNewTab = async (url) => {
        //await userService.addDataToUserState(user.id, user.state)
        dispatch(headerActions.handleOpenBglTokenUpdateFlow());
        dispatch(headerActions.handleOpenBGLConsentSpinner());
        let height = 600;
        let width = 900;
        let left = (window.screen.width - width) / 2;
        let top = (window.screen.height - height) / 2;
        window.open(url, "_blank", `location=yes,top=${top},left=${left},width=${width},height=${height}`);
    };

    return (
        <>
            {isUserLoggedIn && user && user.email && (
                <Box className={classes.createAccountBox} style={{ width: "100%", paddingLeft: "45%" }}>
                    {!user.bglIsValid && (
                        <Grid>
                            <Grid container>
                                <a href="/">
                                    <img
                                        src={ConsentExpired}
                                        style={{ height: 75, width: 150 }}
                                        alt="consent_expired"
                                    />
                                </a>
                            </Grid>
                            <Grid container mt={0} spacing={6}>
                                <Grid>
                                    <Typography mt={2} className={classes.text28} style={{ textAlign: "center" }}>
                                        Consent Expired
                                    </Typography>
                                    <Typography
                                        className={classes.text14}
                                        style={{ color: "#EC0018", textAlign: "center" }}
                                    >
                                        Take a moment to consent so you can access your list of funds
                                    </Typography>
                                    <Box sx={{ marginTop: "20px", marginLeft: "15%" }}>
                                        <Box variant="small">
                                            <div>
                                                <TertiaryBtnDark20
                                                    onClick={() =>
                                                        openInNewTab(
                                                            `${integrationsUri}/u/oauth/authorize?response_type=code&client_id=${bglClientId}&scope=investment&redirect_uri=${redirectUri}&state=${user.state}`
                                                        )
                                                    }
                                                    value={"Login and Consent"}
                                                />
                                            </div>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {user.bglIsValid && Math.abs(user.bglTokenExpirationDate - moment().unix()) > 0 && (
                        <Grid>
                            <Grid container>
                                <a href="/">
                                    <img
                                        src={ConsentApproved}
                                        style={{ height: 75, width: 150 }}
                                        alt="consent_approved"
                                    />
                                </a>
                            </Grid>
                            <Grid container mt={0} spacing={6}>
                                <Grid container>
                                    <Typography mt={2} style={{ marginLeft: "-50px" }} className={classes.text28}>
                                        Your BGL Account is linked
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Typography className={classes.text14} style={{ color: "#2AD000" }}>
                                        (expires in{" "}
                                        {Math.ceil(
                                            Math.abs(user.bglTokenExpirationDate - moment().unix()) / (60 * 60 * 24)
                                        )}{" "}
                                        days)
                                    </Typography>
                                </Grid>
                            </Grid>
                            {
                                <Box sx={{ marginTop: "50px" }}>
                                    <Box variant="small" ml={0} style={{ display: "flex", textAlign: "center" }}>
                                        <Box variant="small">
                                            <div>
                                                <TertiaryBtnDark20
                                                    onClick={() =>
                                                        openInNewTab(
                                                            `${integrationsUri}/u/oauth/authorize?response_type=code&client_id=${bglClientId}&scope=investment&redirect_uri=${redirectUri}&state=${user.state}`
                                                        )
                                                    }
                                                    value={"Login and Consent"}
                                                />
                                            </div>
                                        </Box>
                                    </Box>
                                </Box>
                            }
                        </Grid>
                    )}
                    <BGLConsentSpinner />
                </Box>
            )}
        </>
    );
}